import styled, { css } from 'styled-components';
import { space, typography, position, layout, color, border, flexbox } from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';

const hideSmall = (props) => (
  props.hideSmall && css`
    @media (max-width: ${props => props.theme.breakpoints[1]}) {
      display: none
    };
  `
);

const Container = styled('div').withConfig({ shouldForwardProp })`
  position: relative;
  ${space}
  ${typography}
  ${position}
  ${layout}
  ${color}
  ${border}
  ${flexbox}
  ${props => hideSmall(props)}
`;

export default Container;
