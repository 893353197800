const theme = {
  colors: {
    transparent: 'transparent',
    white: '#FFFFFF',
    black: '#000000',
    primary: [
      '#3545EE'
    ],
    secondary: [
      '#FF6700'
    ],
    danger: [
      '#E53B44'
    ],
    success: [
      '#63C64D'
    ],
    text: [
      '#666666',
      '#222222',
    ],
    button: [
      '#3545EE',
      '#2a37be'
    ],
    bg: [
      '#FFFFFF',
      '#F7F7F7',
      '#F6EFE8',
    ],
  },
  fonts: {
    heading: '"Nunito Sans", Arial, sans-serif',
    regular: '"Nunito Sans", sans-serif',
  },
  fontSizes: {
    sm: 14,
    md: 16,
    lg: 20,
    xl: 24,
    xxl: 32,
    xxxl: 48,
    xxxxl: 64
  },
  fontWeights: {
    reg: 400,
    bold: 700
  },
  lineHeights: {
    normal: 1.5,
    heading: 1.15
  },
  letterSpacings: {
    normal: 'normal',
  },
  space: {
    none: 0,
    sm: 4,
    md: 8,
    lg: 12,
    xl: 16,
    xxl: 24,
    xxxl: 32,
    xxxxl: 64,
    subsection: 90,
    section: 140
  },
  sizes: {
    sm: 16,
    md: 32,
    lg: 64,
    xl: 128,
    xxl: 256,
    xxxl: 512,
    xxxxl: 768,
    content: 1200
  },
  breakpoints: ['576px', '768px', '992px', '1200px'],
  borders: {
    none: 0,
    sm: '1px solid',
    md: '2px solid',
    lg: '4px solid'
  },
  radii: {
    none: 0,
    sm: 2,
    md: 4,
    lg: 8,
    xl: 16,
    circle: '50%'
  },
  shadows: {
    none: 0,
    min: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)',
    reg: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)',
    max: '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)'
  },
  hoverTime: '0.2s',
  scale: {
    hover: 'scale(1.04)',
    active: 'scale(0.96)'
  }
};

export default theme;
