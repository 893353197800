import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyles } from './src/components/UI'

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {element}
    </ThemeProvider>
  );
}