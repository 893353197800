import React from 'react';
import styled, { css } from 'styled-components';
import { space, typography, color, border, position } from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';

const highlight = (props) => {
  switch (props.highlight) {
    default: 
      return '';

    case true:
      return css`
        &::after {
          content: "";
          position: relative;
          height: 5px;
          width: 75px;
          margin-top: 16px;
          margin-bottom: 1rem;
          background-color: ${props => props.theme.colors.secondary};
          display: block;
          border-radius: 2px;
        };
    
        @media (max-width: ${props => props.theme.breakpoints[0]}) {
          &::after {
            width: 50px;
          };
        }
      `;

    case 'center':
      return css`
        &::after {
          content: "";
          position: relative;
          height: 5px;
          width: 75px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 16px;
          margin-bottom: 1rem;
          background-color: ${props => props.theme.colors.secondary};
          display: block;
          border-radius: 2px;
        };

        @media (max-width: ${props => props.theme.breakpoints[0]}) {
          &::after {
            width: 50px;
          };
        }
      `;
  }
}

const StyledHeading = styled('h1').withConfig({ shouldForwardProp })`
  ${props => highlight(props)}
  ${space}
  ${typography}
  ${color}
  ${border}
  ${position}
`;

const Heading = (props) => {
  const {type, ...rest} = props;

  return (
    <StyledHeading
      as={`${type}`}
      {...rest}
    />
  )
};

Heading.defaultProps = {
  type: 'h1',
};

StyledHeading.defaultProps = {
  color: 'text.1',
  fontWeight: 'bold',
  lineHeight: 'heading'
};

export default Heading;
