export const buttonVariants = {
  variants: {
    border: {
      '&:hover': {
        borderColor: 'white',
        color: 'white'
      },
      '&:focus': {
        borderColor: 'white',
        color: 'white'
      }
    },
  }
};
