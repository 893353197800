import React from 'react';
import styled from 'styled-components';
import { space, color, typography, border, layout } from 'styled-system';
import { Link } from 'gatsby'

const NavItem = styled(({ activeStyle, ...rest }) => <Link activeStyle={{ fontWeight: 'bold' }} {...rest} />)`
  display: block;
  border: none;
  transition: all ${props => props.theme.hoverTime} ease-in-out;
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.colors.text[1]};
  };
  &:focus {
    color: ${props => props.theme.colors.text[1]};
  };
  &.active {
    color: ${props => props.theme.colors.text[1]};
  };
  ${space}
  ${color}
  ${typography}
  ${border}
  ${layout}
`;

NavItem.defaultProps = {
  color: 'text.0',
  fontWeight: 'reg',
  to: '/',
}

export default NavItem;
